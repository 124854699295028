<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<span class="fw-500">
								<b class="mr-2">
									<ShowValue :object="group" object-key="name" label="group name"></ShowValue>
								</b>
								<template v-if="group.activated == 1">
									<v-chip color="#4CAF50" text-color="white" label> Active </v-chip>
									<Status status="Active"></Status>
								</template>
								<template v-if="group.activated == 0">
									<v-chip color="#F44336" text-color="white" label> Inactive </v-chip>
								</template>
							</span>
						</p>
						<v-spacer></v-spacer>
						<v-btn
							v-if="getPermission('category:update')"
							v-on:click="doAction('edit')"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
						><v-btn
							v-if="getPermission('category:delete')"
							v-on:click="doAction('delete')"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-delete</v-icon>Delete</v-btn
						>
						<template v-if="getPermission('asset-stock:update')">
							<v-btn
								v-if="group.activated"
								v-on:click="doAction('deactivate')"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								><v-icon small left>mdi-cancel</v-icon>De-Active</v-btn
							>
							<v-btn
								v-else
								v-on:click="doAction('activate')"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								><v-icon small left>mdi-check-all</v-icon>Active</v-btn
							>
						</template>
						<v-btn
							v-if="false"
							v-on:click="editSubDialog = true"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-dots-triangle</v-icon>Add Sub Group</v-btn
						>
					</div>
					<div class="d-flex mt-4">
						<div class="w-100">
							<div class="mt-2">
								<span class="fw-600"> Description : </span>
								<span class="fw-500">
									<ShowValue :object="group" object-key="description" label="description"></ShowValue>
								</span>
							</div>
						</div>
					</div>
					<div v-if="false" class="d-flex mt-4">
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Total Asset : </span>
								<span class="fw-500">
									<ShowValue :object="group" object-key="total_asset" label="total asset"></ShowValue>
								</span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Perishables Quantity : </span>
								<span class="fw-500">
									<ShowValue
										:object="group"
										object-key="inventory_quantity"
										label="inventory quantity"
									></ShowValue>
								</span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600"> Description : </span>
								<span class="fw-500">
									<ShowValue :object="group" object-key="description" label="description"></ShowValue>
								</span>
							</div>
						</div>
					</div>
					<div v-if="false" class="d-flex">
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600 mr-2">Available Assets : </span>
								<span class="fw-500">
									<span class="fw-500">
										<ShowValue
											:object="group"
											object-key="available_asset"
											label="available assets"
										></ShowValue>
									</span>
								</span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600 mr-2">Depreciation Rate : </span>
								<span class="fw-500">
									<ShowValue
										:object="group"
										object-key="depreciation_rate"
										label="depreciation rate"
									></ShowValue>
								</span>
							</div>
						</div>
						<div class="w-33"></div>
					</div>
					<div v-if="false" class="d-flex">
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Asset Stock Quantity : </span>
								<span class="fw-500">
									<ShowValue
										:object="group"
										object-key="asset_stock_quantity"
										label="asset stock quantity"
									></ShowValue>
								</span>
							</div>
						</div>
						<div class="w-33">
							<div class="mt-2">
								<span class="fw-600">Created On : </span>
								<span class="fw-500">
									<ShowValue :object="group" object-key="created_at" label="created on"></ShowValue>
								</span>
							</div>
						</div>
						<div class="w-33"></div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="groupTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail" v-if="false">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#sub-group" v-if="false">
						<v-icon small left>mdi-dots-triangle</v-icon> Sub Groups</v-tab
					>
					<v-tab href="#asset"> <v-icon small left>mdi-hammer-wrench</v-icon> Assets</v-tab>
					<v-tab href="#asset-stock"> <v-icon small left>mdi-calendar-check</v-icon> Asset Stocks</v-tab>
					<v-tab href="#inventory"> <v-icon small left>mdi-history</v-icon> Perishables</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
					<v-tab v-if="false" href="#work-order">
						<v-icon small left>mdi-clipboard-list</v-icon> Work Orders</v-tab
					>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
				</v-tabs>

				<v-tabs-items v-model="groupTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/group-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Category Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<th width="300px">Total Asset</th>
										<td>
											<ShowValue :object="group" object-key="total_asset" label="total asset"></ShowValue>
										</td>
										<th width="300px">Available Assets</th>
										<td>
											<ShowValue
												:object="group"
												object-key="available_asset"
												label="available assets"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Asset Stock Quantity</th>
										<td>
											<ShowValue
												:object="group"
												object-key="asset_stock_quantity"
												label="asset stock quantity"
											></ShowValue>
										</td>
										<th width="300px">Perishables Quantity</th>
										<td>
											<ShowValue
												:object="group"
												object-key="inventory_quantity"
												label="inventory quantity"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Depreciation Rate</th>
										<td>
											<ShowValue
												:object="group"
												object-key="depreciation_rate"
												label="depreciation rate"
											></ShowValue>
										</td>
										<th width="300px">Created On</th>
										<td>
											<ShowValue :object="group" object-key="created_at" label="created on"></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Description</th>
										<td colspan="3">
											<ShowValue :object="group" object-key="description" label="description"></ShowValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="sub-group">
						<SubGroupListing
							v-if="groupTab == 'sub-group'"
							class="mx-4"
							type-text="Group"
							type="group"
							:type-uuid="uuid"
						></SubGroupListing>
					</v-tab-item>
					<v-tab-item value="asset">
						<AssetListing
							v-if="groupTab == 'asset'"
							class="mx-4"
							relation-type="group"
							:relation-type-id="group.id"
						></AssetListing>
					</v-tab-item>
					<v-tab-item value="asset-stock">
						<AssetStockListing
							v-if="groupTab == 'asset-stock'"
							class="mx-4"
							relation-type="group"
							:relation-type-id="group.id"
						></AssetStockListing>
					</v-tab-item>
					<v-tab-item value="inventory">
						<InventoryListing
							v-if="groupTab == 'inventory'"
							class="mx-4"
							relation-type="group"
							:relation-type-id="group.id"
						></InventoryListing>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="groupTab == 'event'"
							class="mx-4"
							type-text="Group"
							type="group"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="work-order">
						<WorkOrderListing
							v-if="groupTab == 'work-order'"
							class="mx-4"
							type-text="Group"
							type="group"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="groupTab == 'file'"
							class="mx-4"
							type-text="Category Files"
							type="group"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Category"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					group.activated
						? 'To preserve associated records, you could de-activate the Category instead.'
						: null
				"
			>
				<template v-if="group.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Category"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Category"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<CreateOrUpdateGroup
				:dialog="editDialog"
				:uuid="uuid"
				v-on:close="editDialog = false"
				v-on:success="getGroup()"
			></CreateOrUpdateGroup>
			<CreateOrUpdateSubGroup
				:dialog="editSubDialog"
				:db-sub-group="subGroup"
				:group-uuid="uuid"
				v-on:close="editSubDialog = false"
				v-on:success="getSubGroup()"
			></CreateOrUpdateSubGroup>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { GetGroup } from "@/core/lib/group.lib";
import ShowValue from "@/view/components/ShowValue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Files from "@/view/components/Files";
import AssetListing from "@/view/components/AssetListing";
import AssetStockListing from "@/view/components/AssetStockListing";
import InventoryListing from "@/view/components/InventoryListing";
import WorkOrderListing from "@/view/components/WorkOrderListing";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import SubGroupListing from "@/view/components/SubGroupListing";
import CreateOrUpdateGroup from "@/view/components/CreateOrUpdateGroup";
import CreateOrUpdateSubGroup from "@/view/components/CreateOrUpdateSubGroup";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "group-detail",
	title: "Detail Category",
	data() {
		return {
			uuid: null,
			subGroup: {},
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			deleteURL: null,
			deleteDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			editDialog: false,
			editSubDialog: false,
			pageLoading: false,
			groupTab: null,
			group: {},
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.editDialog = true;
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		getSubGroup() {
			this.subGroup = {};
		},
		getGroup() {
			GetGroup(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.group = data;
					this.deleteText = `${data.name}`;
					this.deleteURL = `group/${data.uuid}`;
					this.activateText = `${data.name}`;
					this.activateURL = `group/${data.uuid}/activate`;
					this.deactivateText = `${data.name}`;
					this.deactivateURL = `group/${data.uuid}/de-activate`;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Category", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		ShowValue,
		AssetListing,
		AssetStockListing,
		InventoryListing,
		WorkOrderListing,
		DeleteTemplate,
		EventListing,
		CreateOrUpdateGroup,
		CreateOrUpdateSubGroup,
		ActivateTemplate,
		DeactivateTemplate,
		SubGroupListing,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Category", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getGroup();

		EventBus.$on("update:sub-group", (row) => {
			this.subGroup = row;
			this.editSubDialog = true;
		});
	},
	beforeDestroy() {
		EventBus.$off("update:sub-group");
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
