<template>
	<Dialog dense :dialog="dialog" :dialog-width="dialogWidth">
		<template v-slot:title>{{ getTitle() }}</template>
		<template v-slot:body>
			<div class="mx-4">
				<v-form
					ref="subGroupForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-row class="px-4">
						<v-col md="4" class="my-auto py-0">
							<label for="name" class="btx-label mt-2 required">Name</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="name"
								placeholder="Name"
								v-model="subGroup.name"
								:rules="[vrules.required(subGroup.name, 'Name')]"
								:class="{
									required: !subGroup.name,
								}"
							></TextInput>
						</v-col>
						<v-col md="4" class="py-0">
							<label for="description" class="btx-label mt-2">Description</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="description"
								placeholder="Description"
								v-model="subGroup.description"
							></TextAreaInput>
						</v-col>
						<v-col md="12" class="min-height-40"> </v-col>
					</v-row>
				</v-form>
			</div>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				:disabled="pageLoading || !formValid"
				:loading="pageLoading"
				v-on:click="updateOrCreate()"
			>
				Save
			</v-btn>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				:loading="pageLoading"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import { isEmpty, toSafeInteger } from "lodash";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateSubGroup, UpdateSubGroup } from "@/core/lib/group.lib";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "sub-group-template",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		dbSubGroup: {
			type: Object,
			default() {
				return {};
			},
		},
		groupUuid: {
			type: String,
			default: null,
		},
	},
	watch: {
		dialog(param) {
			if (param) {
				this.init();
			}
		},
	},
	data() {
		return {
			uuid: null,
			barcode: null,
			subGroup: {
				name: null,
				description: null,
			},
			formValid: true,
			pageLoading: false,
		};
	},
	components: {
		Dialog,
		TextInput,
		TextAreaInput,
	},
	methods: {
		init() {
			this.subGroup = {
				name: null,
				description: null,
			};
			if (!isEmpty(this.dbSubGroup)) {
				this.subGroup = this.dbSubGroup;
				this.uuid = this.dbSubGroup.uuid;
			}
		},
		getTitle() {
			if (this.uuid) {
				return this.subGroup.name;
			}
			return "Create new Sub Group";
		},
		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.subGroupForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.subGroupForm.validate()) {
				return false;
			}

			const formData = {
				name: _this.subGroup.name,
				description: _this.subGroup.description,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					await UpdateSubGroup(_this.groupUuid, _this.uuid, formData);

					_this.$emit("close", true);
					_this.$emit("success", true);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Sub Group has been updated." },
					]);
				} else {
					await CreateSubGroup(_this.groupUuid, formData);

					_this.$emit("close", true);
					_this.$emit("success", true);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Sub Group has been created." },
					]);
				}
				EventBus.$emit("reload:sub-group", true);
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	computed: {
		...mapGetters(["errors"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
